/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
@import '@fortawesome/fontawesome-free/css/all.css';
@import './styles/mat-input-fields-style-override/orders-inputs.scss';
@import './styles/mat-input-fields-style-override/mat-intput-borders.scss';
@import './styles/main-table-container/main-table-container.scss';

html,
body {
  height: 100%;
  --gap: 1em;
  z-index: 1;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.1px !important;
}

.mat-sort-header-content {
  text-align: left;
  display: flex;
  align-items: center;
}

td {
  font-weight: 400;
  font-size: 14px;
}
.mat-mdc-dialog-container .mdc-dialog__content,
.mat-mdc-dialog-container .mdc-dialog__title {
  font-family: 'Lato', sans-serif !important;
  letter-spacing: 0.1px !important;
}

.mat-drawer-container {
  height: 100vh;
}

.mat-mdc-form-field-icon-suffix,
[dir='rtl'] .mat-mdc-form-field-icon-prefix {
  padding: 0px;
}
.mat-horizontal-stepper-header-container{
  .mat-step-label{
      padding: 0 !important;
  }
}
.mat-stepper-vertical, .mat-stepper-horizontal{
  background: none !important;
}
.mat-mdc-paginator-container {
  justify-content: space-between !important;
}

.mat-mdc-paginator-page-size-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--mat-sidenav-content-text-color);
}

.mat-mdc-paginator-range-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--mat-sidenav-content-text-color);
}

.mat-mdc-paginator {
  padding-top: 20px;
}

.custom-hr {
  border-top: 1px solid #f8f9fd;
}

.hovered {
  cursor: pointer;
}

.mdc-switch__handle-track {
  transform: translateX(0px) !important;
  color: #fff !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: #364fc7 !important;
  border-color: #364fc7 !important;
}

.center {
  text-align: center;
}
.blue {
  color: #364fc7;
}
table {
  tr {
    &:hover {
      background: var(--main-content-background-color) !important;
    }
  }
}

:disabled {
  cursor: default;
  background-color: -internal-light-dark(
    rgba(239, 239, 239, 0.3),
    rgba(59, 59, 59, 0.3)
  ) !important;
  color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170)) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.w-100 {
  width: 100%;
}
.right-button {
  float: right;
}
.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #386ac7 !important;
}

.card-title {
  button {
    color: #364fc7 !important;
    border-color: #364fc7 !important;
    font-size: 14px !important;
    font-weight: 400;
  }
}

.custom-input {
  background: var(--main-content-background-color) !important;
}

.container {
  width: 300px;
  padding: 20px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}

.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  color: #73788c;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 15px;
  padding: 0 5px;
  background: #fff;
}

.mdc-dialog__actions {
  padding: 20px 24px 0px 24px !important;
}
.warn {
  color: var(--mat-form-field-error-text-color) !important;
  border-color: var(--mat-form-field-error-text-color) !important;
}

.did-floating-input,
.did-floating-select {
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background: #fff;
  color: #49454f;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid rgba(115, 120, 140, 0.8);
  box-sizing: border-box;

  &:focus {
    outline: none;

    ~ .did-floating-label {
      top: -7px;
      font-size: 12px;
    }
  }
}

.did-floating-label-focus {
  top: -7px;
  font-size: 12px;
}
select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -7px;
  font-size: 13px;
}

.did-floating-select:not([value='']):valid ~ .did-floating-label {
  top: -7px;
  font-size: 13px;
}

.did-floating-select[value='']:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input {
  .did-floating-input,
  .did-floating-select {
    border: 2px solid #9d3b3b;
    color: #9d3b3b;
  }

  .did-floating-label {
    font-weight: 600;
    color: #9d3b3b;
  }

  .did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
  }
}

.input-group {
  display: flex;

  .did-floating-input {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 0;
  }
}

.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}

.main-content {
  margin: var(--gap);
  margin-top: 0px;
}

.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3d85d8;
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.remove-border-bottom
  > .mat-mdc-tab-header
  .mdc-tab-indicator__content--underline {
  border-top-style: none;
}

mat-card {
  margin: var(--gap);
  padding: 0px;
  width: auto;
  flex-grow: 1;
  display: flex;
  background: var(--mdc-elevated-card-container-color);

  > * {
    flex: 0 0 auto;

    margin: var(--gap);
  }

  .card-title {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 20px;
    color: #1c1b1f;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-content {
    .single-column {
      width: 100%;
    }

    .content-item {
      p {
        font-weight: 400;
        font-size: 14px;
        color: var(--mat-sidenav-content-text-color);
      }
    }
  }

  .no_shadow {
    box-shadow: none !important;
  }
}

#snav > .mat-drawer-inner-container {
  overflow: hidden !important;
}
.mdc-dialog__content {
  overflow: hidden !important;
}

.empty-table-view > p {
  text-align: center;
  justify-content: center;
}

.no_pointer *,
.no_pointer > * {
  cursor: default !important;
}

.mat-mdc-form-field-infix {
  width: 145px !important;
}

input.ng-invalid.ng-touched {
  border: red 1px solid;
  color: red;
}

input.ng-invalid.ng-touched ~ label {
  color: red;
}

.tel-form input.ng-invalid.ng-touched {
  border: none;
  color: red;
}

.tel-form.ng-invalid.ng-touched {
  border: red 1px solid;
  color: red;
}

input:read-only {
  color: rgb(172, 172, 172);
  border: 1px solid rgb(159 164 185 / 80%);
  cursor: default;
}

.shared-details-content-container{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.shared-details-content-title{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;
  color: #787D8F;
  font-weight: normal;
}
.shared-details-content-desc{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  padding-top: 5px;
  color: #1C1B1F;
}
.shared-details-content-grid{
  display: grid;
  gap: 10px;
  &.grid-col-2{
    grid-template-columns: repeat(2,1fr);
  }
  &.grid-col-3{
    grid-template-columns: repeat(3,1fr);
  }
  &.grid-col-4{
    grid-template-columns: repeat(4,1fr);
  }
}
.main-documents-content{
  .mat-mdc-table{
    margin: 0 !important;
  }
}
.disabled-step-icon {
  .mat-step-icon {
    background: red !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.38) !important;
    opacity: .4;
    pointer-events: none;
  }
}

.order-progress-stepper {
  margin-top: -10px;
  .mat-stepper-label-position-bottom{
    .mat-horizontal-stepper-header::after {
      top: calc(calc((var(--mat-stepper-header-height) - 32px) / 2) + 12px);
    }
    .mat-stepper-horizontal-line {
      margin: 0;
      min-width: 0;
      position: relative;
      top: calc(calc((var(--mat-stepper-header-height) - 32px) / 2) + 12px);
    }
    .mat-horizontal-stepper-header::before {
      top: calc(calc((var(--mat-stepper-header-height) - 32px) / 2) + 12px);
    }
  }
  .mat-step-header {
    .mat-step-icon {
      width: 20px;
      height: 20px;
      &.mat-step-icon-state-grayed-out {
        opacity: 0.4;
        background: #666 !important;
        width: 16px;
        height: 16px;
      }

      &.mat-step-icon-state-completed {
        background: #46464f !important;
        color: #FFF;
        width: 20px;
        height: 20px;
        mat-icon {
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.mat-step-icon-state-current {
        background: #343dff !important;
        color: #FFF;
        width: 20px;
        height: 20px;
        mat-icon {
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.mat-step-icon-state-error {
        background: #b3261e !important;
        color: #FFF;
        font-size: 12px;
        width: 20px;
        height: 20px;

        mat-icon {
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.mat-step-text-label {
  .step-title{
    font-size: 13px !important;
    font-weight: normal;
    color: #787D8F;
  }
  .step-description{
    font-size: 13px !important;
    font-weight: normal;
    color: #1C1B1F;
  }
}


.full-width-tooltip {
  .mdc-tooltip__surface{
    max-width: unset !important;
  }
}
// .mat-step-icon {
//   opacity: .4;
//   background: red !important;
// }

// CUSTOM SCROLLBAR:

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #FFF;
}

::-webkit-scrollbar-thumb {
  background: #5966a3;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#cdk-overlay-0{
  .mat-mdc-menu-panel{
    background: #FFF !important;
  }
  .mat-mdc-menu-content{
    padding: 0 !important;
    .mat-mdc-menu-item{
      min-height: 32px;
    }
    .mat-mdc-menu-item-text{
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}
.mat-elevation-z2{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
}
.mat-mdc-elevation-specific{
  .mat-elevation-z2{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  }
}
.step-title{
  color: #222;
  padding: 0 !important;
  margin: 10px 0 5px 0 !important;
  font-weight: 400 !important;
}
.title-card{
  .mat-mdc-card-content{
    padding: 0 !important;
  }
}
.mat-mdc-dialog-container {
  .mat-mdc-dialog-content{
    padding-top: 0 !important;
  }
}
.mat-horizontal-content-container{
  padding: 0 !important;
}
.order-info-details-container{
  .mat-mdc-button{
    height: min-content !important;
    padding: 5px 10px !important;
    .mat-mdc-button-touch-target{
      height: min-content !important;
    }
  }
}
.order-progress-stepper{
  .mat-stepper-horizontal-line{
    border-top-color: #E5EAEF;
  }
  .mat-horizontal-stepper-header{
    &:before{
      border-top-color: #E5EAEF;
    }
    &:after{
      border-top-color: #E5EAEF;
    }
  }
}
// .mat-mdc-form-field-type-mat-select{
//   .mat-mdc-form-field-infix{
//     padding-top: 3px !important;
//     padding-bottom: 0 !important;
//   }
// }
// .mdc-text-field--outlined{
//   .mat-mdc-form-field-infix{
//     padding-top: 3px !important;
//     padding-bottom: 0 !important;
//   }
// }
