.filters-inputs{
    .mat-button-toggle-checked .mat-button-toggle-checkbox-wrapper {
        width: auto;
        height: auto;
    }
    .mat-mdc-input-element {
        height: 100% !important;
        font-size: 14px !important;
      }
      .mat-mdc-text-field-wrapper {
        background-color: #fff;
        font-size: 14px !important;
      }
      .mat-button-toggle-group-appearance-standard {
        border-radius: 5px;
        border: 1px solid #c2c9d1;
        font-size: 14px !important;
      }
      mat-label {
        font-size: 14px !important;
      }
      .mat-button-toggle-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        border: none !important;
        height: 48px;
        padding-right: 4px;
        padding-bottom: 2px;
        button {
          border-radius: 5px !important;
          margin: 5px;
        }
      }
      .mat-button-toggle-button{
        background: #FFF !important;
      }
      .mat-button-toggle-appearance-standard {
        border: 1px solid #c2c9d1 !important;
        background: #fff !important;
        border-radius: 5px;
        min-height: 46px;
        height: 46px;
        .mat-button-toggle-label-content {
          &:not(:has(.mat-mdc-form-field-textarea-control)) {
            padding: 0 !important;
            line-height: 46px !important;
          }
        }
      }
      .mat-button-toggle-appearance-standard {
        display: block !important;
      }
      .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
        background: none !important;
      }
      .mdc-notched-outline {
        .mat-mdc-notch-piece:not(.mdc-notched-outline__notch) {
          border-left-color: #c2c9d1 !important;
          border-right-color: #c2c9d1 !important;
        }
        .mat-mdc-notch-piece {
          border-top-color: #c2c9d1 !important;
          border-bottom-color: #c2c9d1 !important;
        }
      }
      .mat-mdc-text-field-wrapper {
        &:not(:has(.mat-mdc-form-field-textarea-control)) {
          height: 46px !important;
        }
        .mat-mdc-form-field-infix {
          &:not(:has(.mat-mdc-form-field-textarea-control)) {
            height: 46px !important;
            padding: 0 !important;
          }
        }
        .mat-mdc-form-field-flex {
          &:not(:has(.mat-mdc-form-field-textarea-control)) {
            height: 46px !important;
          }
        }
      }
      .mat-mdc-input-element {
        // INPUT PADDING
        &:not(:has(.mat-mdc-form-field-textarea-control)) {
          height: 46px !important;
          padding: 10px 0 !important;
          box-sizing: border-box;
        }
      }
      .mat-form-field-hide-placeholder {
        .mdc-floating-label {
          // 43px / 2
          top: 21px !important;
        }
      }

    .mat-button-toggle-appearance-standard {
        height: 46px;
      }
    .mat-button-toggle-group
        button {
        margin: 0;
      }
      .mat-button-toggle-appearance-standard {
        position: relative;
        &::before {
          transform: translateY(-50%);
          position: absolute;
          top: calc(50% + 1px);
          left: 20px;
          font-family: 'Material Icons';
          -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
          content: 'radio_button_unchecked';
          color: #364fc7;
          font-size: 24px;
          width: 24px;
          height: 24px;
          z-index: 10;
          pointer-events: inherit;
        }
        .mat-pseudo-checkbox {
          display: none;
        }
      }
      .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
        &::before {
          content: 'check_circle';
        }
      }
      .mat-button-toggle-button:has(.mat-button-toggle-checkbox-wrapper) {
        padding-left: 0;
      }
      .mdc-checkbox__background {
        border-color: #c2c9d1 !important;
      }
}
// SELECT FIELDS:
.mat-mdc-select-value-text{
  display: flex !important;
  align-items: center;
  margin-top: 12px;
}
.mat-mdc-select-value{
  font-size: 14px !important;
  height: 46px !important;
}
.mat-mdc-option .mdc-list-item__primary-text{
  font-size: 14px !important;
}
.mat-mdc-select{
  height: 46px !important;
}
.mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix{
  padding: 0 !important;
}