// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$backoffice-dashboard-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$backoffice-dashboard-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$backoffice-dashboard-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$backoffice-dashboard-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $backoffice-dashboard-primary,
      accent: $backoffice-dashboard-accent,
      warn: $backoffice-dashboard-warn,
    ),
  )
);
$backoffice-dashboard-theme-dark: mat.m2-define-light-theme(
  (
    color: (
      primary: $backoffice-dashboard-primary,
      accent: $backoffice-dashboard-accent,
      warn: $backoffice-dashboard-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($backoffice-dashboard-theme);

$m3-dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$blue-palette,
      tertiary: mat.$blue-palette,
    ),
  )
);

$m3-light-theme: mat.define-theme(
  (
    color: (
      primary: mat.$blue-palette,
      tertiary: mat.$blue-palette,
    ),
  )
);

[data-theme='dark'] {
  @include mat.all-component-themes($m3-dark-theme);
  --mat-tab-header-inactive-label-text-color: #ffffff;
  --main-content-background-color: #333333;
  --main-content-text-color: #ffffff;
  --mat-sidenav-container-shape: 0px;
}

[data-theme='light'] {
  @include mat.all-component-themes($m3-light-theme);
  --mdc-switch-selected-icon-color: #ffffff;
  --mat-sidenav-container-shape: 0px;
  --header-background-color: #ffffff;
  --header-border-color: #e5e8ea;
  --header-menu-item-color: #231f20;
  --header-theme-switch-background-color: #cccccc;
  --header-theme-switch-before-background-color: #ffffff;
  --header-theme-switch-checked-background-color: #2196f3;
  --main-content-background-color: #ffffff;
  --main-content-text-color: #231f20;
  --mat-expansion-container-text-color: #afafaf;
  --mat-expansion-header-indicator-color: #afafaf;
  --mdc-list-list-item-label-text-color: #afafaf;
  --mat-expansion-container-background-color: #354156;
  --mdc-list-list-item-hover-label-text-color: #ffffff;
  --mdc-list-list-item-focus-label-text-color: #ffffff;
  --mat-switch-selected-with-icon: 0px;
  --mat-sidenav-container-text-color: #f5f5f5cc;
  --mdc-protected-button-container-color: #364fc7;
  --breadcrums-text-color: #364fc7;
  --p-gray: #73788c;
  --mdc-outlined-button-container-shape: 6px;
  --mdc-filled-button-container-shape: 6px;
  --mdc-elevated-card-container-shape: 6px;
  --mdc-elevated-card-container-color: #fff;
  --main-content-background-color: #f8f9fc;
  --mat-app-background-color: #f8f9fc;
  --mdc-text-button-container-shape: 6px;
  --mdc-dialog-container-shape: 6px;
  --mdc-protected-button-container-shape: 6px;
  --mdc-dialog-container-color: #fff;
  --mat-table-background-color: #fff;
  --mdc-switch-track-height: 18px;
  --mat-switch-with-icon-handle-size: 26px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 0px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 26px;
  --mat-select-panel-background-color: #ffffff;
  --mat-datepicker-calendar-container-background-color: #ffffff;
  --mat-paginator-container-background-color: #ffffff;
}
