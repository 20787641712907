.main-table-container{
    table{
      width: 100%;
      tr{
        height: 40px;
      }
    }
    .mat-mdc-header-row{
      position: sticky;
      top: 5px;
      background-color: rgb(248, 249, 252);
    }
    .mat-mdc-header-cell{
      border-bottom: 1px solid #f0f2f3;
      padding: 2px 10px;
      color: #1C1B1F;
      background-color: #F1F5FF;
      &:first-child{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  
    .mat-mdc-row .mat-mdc-cell {
      border-bottom: 1px solid #f0f2f3;
      padding:  0px 10px;
      cursor: pointer;
      &:first-child{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
}